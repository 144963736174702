import { CardContainer } from 'components/markdown/card-container.tsx';
import { ContactCallout } from 'components/markdown/contact-callout.tsx';
import ExpandableCard, { type ExpandableItemType } from 'components/markdown/expandable-card.tsx';
import { Hero } from 'components/markdown/hero';
import { type PageProps, navigate } from 'gatsby';
import { type Workflow, useWorkflowsData } from 'hooks';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { PageLayout } from 'src/layouts/page-layout';

export const JourneyExplorer = ({ workflows, location }: { workflows: Workflow[]; location: Location }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      window.scrollTo(0, Number.parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem('scrollPosition');
    }
  }, []);

  return (
    <div data-testid="workflows-page" className="flex flex-col gap-[42px]">
      <Hero
        type="large"
        headline={formatMessage({ id: 'workflows.title' })}
        copy={'See what our end-to-end customer journey and features look like'}
        image={{
          name: 'journey-explorer.png',
          nameDark: 'journey-explorer-dark.png',
          altText: 'Journey explorer',
        }}
      />
      <CardContainer>
        {workflows
          ?.sort((a, b) => {
            return a.heading === 'Demopay' ? -1 : b.heading === 'Demopay' ? 1 : a.heading.localeCompare(b.heading);
          })
          ?.map((workflow) => (
            <ExpandableCard
              heading={workflow.heading}
              key={workflow.heading}
              description={workflow.description}
              items={workflow.workflows.map((item) => ({
                id: item.workflowId,
                name: item.summary,
                type: item.tag as ExpandableItemType,
                description: item.description,
                href: `${workflow.link}/${item.workflowId}`,
              }))}
              defaultExpanded={location.pathname.replace(/\/$/, '') === workflow.link}
              onExpandedChange={(isExpanded) => {
                const currentScrollPosition = window.scrollY.toString();
                sessionStorage.setItem('scrollPosition', currentScrollPosition);
                isExpanded ? navigate(workflow.link) : navigate('/journey-explorer');
              }}
            />
          ))}
      </CardContainer>

      <ContactCallout
        title="Still have questions?"
        description="Can’t find the answer to your question? Our friendly team are more than happy to help"
        cta={{
          label: 'Get in touch',
          href: '/api-products/open-banking/open-banking-support',
        }}
      />
    </div>
  );
};

export const JourneyExplorerPage = ({ location }: PageProps) => {
  const workflows = useWorkflowsData();
  const commitDate = workflows.length > 0 ? Math.max(...workflows.map((workflow) => workflow.commitDate)) : undefined;

  return (
    <PageLayout location={location} isLargeContent title="Journey Explorer" commitDate={commitDate} isGreyLayout>
      <JourneyExplorer workflows={workflows} location={location} />
    </PageLayout>
  );
};

export default JourneyExplorerPage;
